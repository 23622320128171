import React, { useState } from 'react';
import {
  Table,
  Button,
  Form,
  message,
  Popconfirm,
  Tooltip,
  Row,
  Col,
  Input,
  Empty,
  Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  CheckOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import EditableCell from './EditableCell';

const { Search } = Input;

const EditableTable = ({
  data,
  totalDataCount,
  columns,
  handlePageNumberChange,
  handleDelete,
  handleSave,
  handleSearch,
  isFilterActivated,
  extraOperation,
  propsForSelector,
  currentPageNumber,
  operationsDisabledStatus = false,
  updatable = true,
  deletable = true,
  emptyDescription,
  toggleRelease,
  enableCSVExport = false,
  csvExportButtonText = 'Export CSV',
  handleCSVExport,
  exportLoading = false,
}) => {
  // hooks
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [errorsMessages, setErrorsMessages] = useState();

  // handles
  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setErrorsMessages();
    setEditingKey('');
  };

  const save = async () => {
    try {
      await form.validateFields();
      const fieldsValue = await form.getFieldsValue();
      const recordId = await form.getFieldValue('id');
      fieldsValue.id = recordId;

      const {
        finishEdit,
        errorsMessages: currentErrorsMessages,
      } = await handleSave(fieldsValue);
      if (finishEdit) {
        setEditingKey('');
      } else {
        setErrorsMessages(currentErrorsMessages);
      }
    } catch (errInfo) {
      message.error(t('errors.pleaseCheckInputs'));
      console.error('Validate Failed:', errInfo);
    }
  };

  // data formatting
  const isEditing = (record) => record.key === editingKey;

  const columnsWithOperations = [
    ...columns,
    {
      title: t('titles.action'),
      dataIndex: 'operations',
      key: 'operations',

      render: (_, record) => {
        const disabledStatusDependsOnRelease =
          (record?.__typename === 'PeriodicAdvertisement' ||
            record?.__typename === 'SpecialAdvertisement') &&
          record?.isReleased;

        const editable = isEditing(record);

        return editable ? (
          <>
            <Tooltip title={t('buttons.save')}>
              <Button
                ghost
                type="primary"
                onClick={() => save()}
                icon={<CheckOutlined />}
                style={{ marginRight: '2px', marginBottom: 5 }}
              />
            </Tooltip>

            <Tooltip title={t('buttons.cancel')}>
              <Button
                onClick={cancel}
                ghost
                type="danger"
                icon={<CloseOutlined />}
                style={{ marginRight: '2px', marginBottom: 5 }}
              />
            </Tooltip>
          </>
        ) : (
          <>
            {extraOperation && extraOperation(record, toggleRelease)}
            <Tooltip title={t('buttons.edit')}>
              <Button
                style={{ marginRight: '5px', marginBottom: 5 }}
                icon={<EditOutlined />}
                ghost
                type="primary"
                disabled={
                  editingKey !== '' ||
                  operationsDisabledStatus ||
                  disabledStatusDependsOnRelease ||
                  !updatable
                }
                onClick={() => edit(record)}
              />
            </Tooltip>

            <Popconfirm
              title={t('messages.sure')}
              okText={t('buttons.yes')}
              cancelText={t('buttons.no')}
              disabled={
                editingKey !== '' ||
                operationsDisabledStatus ||
                disabledStatusDependsOnRelease ||
                !deletable
              }
              onConfirm={() => handleDelete(record.id)}
            >
              <Tooltip title={t('buttons.delete')}>
                <Button
                  icon={<DeleteOutlined />}
                  ghost
                  type="danger"
                  style={{ marginRight: '5px', marginBottom: 5 }}
                  disabled={
                    editingKey !== '' ||
                    operationsDisabledStatus ||
                    disabledStatusDependsOnRelease ||
                    !deletable
                  }
                />
              </Tooltip>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const mergedColumns = columnsWithOperations.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => {
        return {
          form,
          inputType: col.inputType,
          errorsMessages,
          rules: col.rules,
          record,
          dataIndex: col.dataIndex,
          editing: isEditing(record),
          propsForSelector: col.propsForSelector,
        };
      },
    };
  });

  return (
    <>
      {isFilterActivated && (
        <Row gutter={[16, 16]} align="middle" justify="end">
          <Col>
            {enableCSVExport && (
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={handleCSVExport}
                loading={exportLoading}
                style={{ marginRight: '16px' }}
              >
                {t('buttons.exportCSV', csvExportButtonText)}
              </Button>
            )}
            <Search
              placeholder={t('placeholders.search')}
              onSearch={handleSearch}
              enterButton
              style={{ width: enableCSVExport ? 250 : '100%' }}
            />
          </Col>
        </Row>
      )}

      {!isFilterActivated && enableCSVExport && (
        <Row style={{ marginBottom: '16px' }}>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={handleCSVExport}
              loading={exportLoading}
            >
              {t('buttons.exportCSV', csvExportButtonText)}
            </Button>
          </Col>
        </Row>
      )}

      <Form form={form} component={false}>
        <Row>
          <Col md={24} sm={24} xs={24}>
            <Table
              scroll={{ x: 'auto' }}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              columns={mergedColumns}
              bordered
              dataSource={data}
              rowKey={(record, key) => {
                // eslint-disable-next-line no-param-reassign
                record.key = key;
                return key;
              }}
              pagination={{
                current: currentPageNumber,
                pageSize: 10,
                responsive: true,
                total: totalDataCount,
                onChange: handlePageNumberChange,
              }}
              locale={{
                emptyText: (
                  <Empty
                    description={emptyDescription || t('warnings.noData')}
                  />
                ),
              }}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EditableTable;
