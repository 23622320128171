import React, { useState, useCallback } from 'react';
import { Row, Col, Divider } from 'antd';
import './partner.css';
import { useTranslation } from 'react-i18next';
import IntegratedEditableTable from '../../components/IntegratedEditableTable/Index';
import {
  CREATE_PARTNER,
  PARTNERS,
  UPDATE_PARTNER,
  DELETE_PARTNER,
} from './gql';
import ModalFormBuilder from '../../components/ModalFormBuilder';
import humanizeTimestamp from '../../utils.js/humanizeTimestamp';

const fieldsCreator = (t) => [
  {
    name: 'name',
    label: t('titles.partnerName'),
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
  {
    name: 'userId',
    label: t('titles.username'),
    inputType: 'userSelector',
    propsForSelector: { role: 'PARTNER' },
  },
  {
    name: 'partnerSectorId',
    label: t('titles.partnerSector'),
    inputType: 'partnerSectorSelector',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
  {
    name: 'description',
    label: t('labels.signboard'),
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
];

const columnsConfig = (t) => [
  {
    title: t('titles.username'),
    dataIndex: 'userId',
    key: 'userId',
    inputType: 'userSelector',
    propsForSelector: { role: 'PARTNER' },
    render: (userId, record) => {
      return record.username || t('warnings.noData');
    },
    editable: true,
  },
  {
    title: t('titles.partnerName'),
    dataIndex: 'name',
    key: 'name',
    editable: true,
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
  {
    title: t('titles.partnerSector'),
    dataIndex: 'partnerSectorId',
    key: 'partnerSectorId',
    inputType: 'partnerSectorSelector',
    width: '13%',
    render: (partnerSectorId, record) => {
      return record.partnerSectorName || t('warnings.noData');
    },
    editable: true,
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
  {
    title: t('labels.signboard'),
    dataIndex: 'description',
    key: 'description',
    render: (description, record) => {
      return record.description || t('warnings.noData');
    },
    editable: true,
    width: '15%',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
  {
    title: t('columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: '15%',
  },
  {
    title: t('columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: '15%',
  },
];

export default function Partner() {
  const { t } = useTranslation();
  const [visibility, toggleModal] = useState(false);
  const toggleTrueFalse = () => toggleModal(!visibility);

  const fields = fieldsCreator(t);

  const columns = columnsConfig(t);

  const afterGetFunc = useCallback((response) => {
    const newData = response?.partners?.data?.map((partner) => ({
      id: partner.id,
      name: partner.name,
      description: partner.description,
      username: partner.user.username,
      userId: partner.user.id,
      partnerSectorId: partner.partnerSector?.id,
      partnerSectorName: partner.partnerSector?.name,
      createdAt: humanizeTimestamp(partner.createdAt),
      updatedAt: humanizeTimestamp(partner.updatedAt),
    }));

    return { ...response, partners: { ...response?.partners, data: newData } };
  }, []);
  return (
    <Row gutter={16}>
      <Divider orientation="center">{t('header.partner')}</Divider>
      <Col span={5}>
        <ModalFormBuilder
          visibility={visibility}
          toggleModal={toggleTrueFalse}
          modalTitle={t('titles.newPartner')}
          showModalButtonLabel={t('titles.newPartner')}
          fields={fields}
          postReq={CREATE_PARTNER}
          postResIndex="createPartner"
          postResFieldForUpdating="partner"
          willBeUpdatedQuery={PARTNERS}
          willBeUpdatedQueryName="partners"
        />
      </Col>
      <Col style={{ marginTop: 10 }} md={24} lg={24} sm={24} xs={24}>
        <IntegratedEditableTable
          columns={columns}
          getReq={PARTNERS}
          getResIndex="partners"
          deleteReq={DELETE_PARTNER}
          deleteResIndex="deletePartner"
          updateReq={UPDATE_PARTNER}
          updateResIndex="updatePartner"
          afterGetFunc={afterGetFunc}
          filterColumns={{ user: ['username'], partner: ['name'] }}
          enableCSVExport
          csvFilenamePrefix="partners"
        />
      </Col>
    </Row>
  );
}
