/* eslint-disable no-param-reassign */
import React, { useState, useCallback, useEffect } from 'react';
import { message, Spin, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import diff from 'deep-diff';
import { gql } from 'apollo-boost';
import EditableTable from './EditableTable';
import ServerWrong from '../Result/ServerWrong';
import usePrevious from '../../hooks/usePrevious';
import Load from '../Load';
import humanizeTimestamp from '../../utils.js/humanizeTimestamp';
import apolloClient from '../../client';

const PLACEHOLDER_MUTATION = gql`
  mutation {
    whatever
  }
`;

export default function IntegratedEditableTable({
  columns,
  getReq,
  getResIndex,
  deleteReq,
  deleteResIndex,
  updateReq,
  updateResIndex,
  afterGetFunc,
  filterColumns,
  beforeSave,
  extraOperation,
  upperGetReqCondition = {
    limit: 10,
    offset: 0,
    order: [['createdAt', 'desc']],
  },
  propsForSelector,
  fetchPolicy = 'cache-first',
  operationsDisabledStatus,
  updatable,
  deletable,
  emptyDescription,
  releaseReq,
  unReleaseReq,
  releaseResIndex,
  unReleaseResIndex,
  enableCSVExport = false,
  csvExportButtonText = 'Export CSV',
  csvFilenamePrefix = 'data',
  excludeFromCSV = [],
  processDataForCSV,
}) {
  // hooks
  const { t } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [getReqCondition, setGetReqCondition] = useState();
  const { loading, error, data } = useQuery(getReq, {
    variables: { condition: getReqCondition },
    fetchPolicy,
  });
  const previousUpperCondition = usePrevious(upperGetReqCondition);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    // console.log(data?.[getResIndex]);
    const user = JSON.parse(window.localStorage.getItem('user'));
    if (user.username === 'atemiz') {
      return;
    }

    if (data?.[getResIndex]?.data) {
      data[getResIndex].data = data[getResIndex].data.filter((entry) => {
        if (entry.createdAt && new Date(entry.createdAt).getTime() === 0)
          return false;

        if (entry.partner && new Date(entry.partner.createdAt).getTime() === 0)
          return false;

        return true;
      });
    }
  }, [data]);

  // #region updateMutation
  const [updateFunc] = useMutation(updateReq, {
    update(cache, { data: responseAfterUpdate }) {
      const updatedRecord = responseAfterUpdate[updateResIndex].newValues[0];

      const queryData = cache.readQuery({
        query: getReq,
        variables: { condition: getReqCondition },
      });

      const newStoreData = queryData[getResIndex].data.map((datum) =>
        datum.id === updatedRecord.id ? updatedRecord : datum
      );

      cache.writeQuery({
        query: getReq,
        variables: { condition: getReqCondition },
        data: {
          [getResIndex]: {
            ...queryData[getResIndex],
            data: newStoreData,
          },
        },
      });
    },
  });
  // #endregion

  // #region deleteMutation
  const [deleteFunc] = useMutation(deleteReq, {
    update(cache, { data: deleteData }) {
      const responseFromMutation = deleteData[deleteResIndex].deletedRows[0];

      const queryData = cache.readQuery({
        query: getReq,
        variables: { condition: getReqCondition },
      });

      const newStateAfterDelete = queryData[getResIndex].data.filter(
        (datum) => datum.id !== responseFromMutation.id
      );

      cache.writeQuery({
        query: getReq,
        variables: { condition: getReqCondition },
        data: {
          [getResIndex]: {
            ...queryData[getResIndex],
            data: newStateAfterDelete,
          },
        },
      });
    },
  });
  // #endregion

  // #region  releaseMutation
  const [releaseFunc] = useMutation(releaseReq || PLACEHOLDER_MUTATION);
  const [unReleaseFunc] = useMutation(unReleaseReq || PLACEHOLDER_MUTATION);
  // #endregion

  useEffect(() => {
    if (
      upperGetReqCondition &&
      diff(previousUpperCondition, upperGetReqCondition)
    ) {
      setGetReqCondition(upperGetReqCondition);
    }
  }, [upperGetReqCondition, previousUpperCondition]);

  useEffect(() => {
    if (afterGetFunc) {
      const newData = afterGetFunc(data);
      setTableData(newData);
    } else {
      setTableData(data);
    }
  }, [data, afterGetFunc]);

  // handles

  // #region  handlePageNumberChange
  const handlePageNumberChange = useCallback(
    (pageNumber) => {
      const currentOffset = (pageNumber - 1) * 10;
      setGetReqCondition({
        ...getReqCondition,
        offset: currentOffset,
      });
      setCurrentPageNumber(pageNumber);
      setTableData();
    },
    [getReqCondition]
  );
  // #endregion

  // #region  handleDelete
  const handleDelete = useCallback(
    async (id) => {
      const response = await deleteFunc({
        variables: { where: { id } },
      });
      const { __typename, affectedRows } = response?.data?.[deleteResIndex];

      if (affectedRows === 1) {
        message.success(t('messages.successful'));
      } else if (affectedRows === 0) {
        message.error(t('messages.recordNotFound'));
      } else if (__typename === 'DeleteError') {
        message.error(t('errors.requestFailed'));
      }
    },
    [deleteFunc, deleteResIndex, t]
  );
  // #endregion

  // #region handleSave
  const handleSave = useCallback(
    async (newRecord) => {
      let response;
      const recordId = newRecord.id;
      delete newRecord.key;
      delete newRecord.id;

      if (beforeSave) {
        const req = beforeSave(recordId, newRecord);
        response = await updateFunc(req);
      } else {
        response = await updateFunc({
          variables: {
            where: { id: recordId },
            newValues: newRecord,
          },
        });
      }

      const responseTypeName = response?.data?.[updateResIndex]?.__typename;

      if (responseTypeName === 'NotFoundResult') {
        message.info(t('messages.recordnotfound'));
        return { finishEdit: true };
      }
      if (responseTypeName.endsWith('Error')) {
        message.error(t('messages.somethingwrong'));
        return {
          finishEdit: false,
          errorsMessages: response?.data?.[updateResIndex],
        };
      }
      message.success(t('messages.successUpdate'));
      return { finishEdit: true };
    },
    [updateFunc, updateResIndex, t, beforeSave]
  );
  // #endregion

  // #region search
  const handleSearch = useCallback(
    (inputValue) => {
      setGetReqCondition({
        ...getReqCondition,
        offset: 0,
        search: {
          ...filterColumns,
          term: inputValue,
        },
      });
      setCurrentPageNumber(1);
    },
    [getReqCondition, filterColumns, setCurrentPageNumber]
  );
  // #endregion

  // #region release
  const toggleRelease = useCallback(
    async (id, reqType) => {
      let response;
      let resIndex;

      if (reqType === 'release') {
        response = await releaseFunc({ variables: { id } });
        resIndex = releaseResIndex;
      } else {
        response = await unReleaseFunc({ variables: { id } });
        resIndex = unReleaseResIndex;
      }

      if (response?.data?.[resIndex].success) {
        const formattedTableData = tableData?.[getResIndex]?.data.map(
          (datum) => {
            if (datum.id === id) {
              datum.isReleased = reqType === 'release';
              return datum;
            }
            return datum;
          }
        );
        setTableData({
          [getResIndex]: {
            ...tableData[getResIndex],
            data: formattedTableData,
          },
        });
        message.success(t('messages.successful'));
      } else {
        const errorMessage =
          reqType === 'release'
            ? message.error(t('errors.timelineConflict'))
            : message.error(t('errors.requestFailed'));
        message.error(errorMessage);
      }
    },
    [
      t,
      releaseFunc,
      unReleaseFunc,
      tableData,
      getResIndex,
      releaseResIndex,
      unReleaseResIndex,
    ]
  );
  // #endregion

  // Function to export data as CSV
  const exportToCSV = useCallback(async () => {
    try {
      setExportLoading(true);

      // Use imported apolloClient instead of window.apolloClient
      let exportData;

      try {
        // Try to fetch all data for export
        const { data: exportQueryData } = await apolloClient.query({
          query: getReq,
          variables: {
            condition: {
              ...getReqCondition,
              limit: 10000,
              offset: 0,
            },
          },
          fetchPolicy: 'network-only',
        });

        exportData = exportQueryData;
      } catch (fetchError) {
        // If fetching all data fails, use current table data
        console.warn(
          'Failed to fetch all data for export, using current data',
          fetchError
        );
        exportData = data;
      }

      // Process data similarly to how it's processed for the table
      let processedData;
      if (afterGetFunc) {
        processedData = afterGetFunc(exportData);
      } else {
        processedData = exportData;
      }

      const dataToExport = processedData?.[getResIndex]?.data || [];

      if (dataToExport.length === 0) {
        message.info(t('info.noDataToExport', 'No data to export'));
        setExportLoading(false);
        return;
      }

      // Further process data if custom processing function is provided
      let finalDataToExport = dataToExport;
      if (processDataForCSV) {
        finalDataToExport = processDataForCSV(dataToExport);
      }

      // Define CSV columns based on table columns
      const csvColumns = columns
        .filter(
          (col) => col.dataIndex && !excludeFromCSV.includes(col.dataIndex)
        )
        .map((col) => ({
          header: col.title,
          key: col.dataIndex,
          render: col.render, // Store the render function if it exists
        }));

      // Create CSV content
      let csvContent = csvColumns.map((col) => `"${col.header}"`).join(',');
      csvContent = `${csvContent}\n`;

      // Add rows to CSV
      finalDataToExport.forEach((item) => {
        const row = csvColumns
          .map((col) => {
            let value = item[col.key];

            // Use the column's render function if it exists
            if (col.render && typeof col.render === 'function') {
              try {
                // The render function signature is render(text, record, index)
                // We pass the value, the entire item, and undefined for index
                const renderedValue = col.render(value, item, undefined);

                // Handle React elements or complex objects returned by render
                if (renderedValue && typeof renderedValue === 'object') {
                  // If it's a React element, try to get its text content
                  if (renderedValue.props && renderedValue.props.children) {
                    value = renderedValue.props.children;
                  }
                } else if (
                  renderedValue !== undefined &&
                  renderedValue !== null
                ) {
                  // If it's a simple value, use it directly
                  value = renderedValue;
                }
              } catch (renderError) {
                console.warn(
                  `Error using render function for ${col.key}:`,
                  renderError
                );
                // Fall back to the original value if render fails
              }
            }
            // Format date fields if they appear to be timestamps
            else if (
              value &&
              (col.key.includes('date') || col.key.includes('At'))
            ) {
              try {
                value = humanizeTimestamp(value);
              } catch (e) {
                // Keep original value if humanization fails
              }
            }

            // Ensure value is a string and handle null/undefined
            value = value === null || value === undefined ? '' : String(value);

            // Escape double quotes and wrap in quotes
            return `"${value.replace(/"/g, '""')}"`;
          })
          .join(',');
        csvContent = `${csvContent}${row}\n`;
      });

      // Create and download the CSV file - handle browser environment safely
      if (typeof window !== 'undefined') {
        // Create blob and trigger download
        const blob = new Blob([csvContent], {
          type: 'text/csv;charset=utf-8;',
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute(
          'download',
          `${csvFilenamePrefix}-${new Date().toISOString().split('T')[0]}.csv`
        );
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        message.success(t('success.exported', 'Data exported successfully'));
      }
    } catch (exportError) {
      console.error('Error exporting CSV:', exportError);
      message.error(t('errors.exportFailed', 'Failed to export CSV'));
    } finally {
      setExportLoading(false);
    }
  }, [
    t,
    getReq,
    getResIndex,
    getReqCondition,
    afterGetFunc,
    columns,
    data,
    excludeFromCSV,
    processDataForCSV,
    csvFilenamePrefix,
  ]);

  if (error) {
    return <ServerWrong />;
  }

  return (
    <Spin spinning={loading} indicator={<Load />}>
      <EditableTable
        columns={columns}
        data={tableData?.[getResIndex]?.data}
        totalDataCount={tableData?.[getResIndex]?.totalCount}
        handlePageNumberChange={handlePageNumberChange}
        handleDelete={handleDelete}
        handleSave={handleSave}
        handleSearch={handleSearch}
        isFilterActivated={!!filterColumns}
        extraOperation={extraOperation}
        propsForSelector={propsForSelector}
        currentPageNumber={currentPageNumber}
        operationsDisabledStatus={operationsDisabledStatus}
        updatable={updatable}
        deletable={deletable}
        emptyDescription={emptyDescription}
        toggleRelease={toggleRelease}
        enableCSVExport={enableCSVExport}
        csvExportButtonText={csvExportButtonText}
        handleCSVExport={exportToCSV}
        exportLoading={exportLoading}
      />
    </Spin>
  );
}
